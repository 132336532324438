import React from 'react';
import { useIntl } from 'react-intl';
import { Details } from 'shared-components';
import PropTypes from 'prop-types';
import { profileEn } from '../../../../i18n/profile';
import Title from '../../../../components/Title';
import { CardInfoGroup, TitleContainer } from '../CardInfo/styles';

const DirectDepositAccountInfo = ({
  accountInfo: { bank_details: bankDetails },
}) => {
  const intl = useIntl();

  const determineAccountDetailsInfo = () => {
    if (bankDetails.routing_number) {
      return {
        routingNumber: bankDetails.routing_number,
        accountNumber: bankDetails.account_number,
      };
    }
    
    return {
      transitNumber: bankDetails.transit_number,
      institutionNumber: bankDetails.institution_number,
      accountNumber: bankDetails.account_number,
    };
  };

  const page = 'profile';

  const determineAccountDetailsColumn = () => {
    if (bankDetails.routing_number) {
      return [
        {
          accessor: 'routingNumber',
          header: intl.messages['profile-account-info-routing-number-label'],
        },
        {
          accessor: 'accountNumber',
          header: intl.messages['profile-account-info-account-number-label'],
        },
      ];
    }

    return [
      {
        accessor: 'transitNumber',
        header: intl.messages['profile-account-info-transit-number-label'],
      },
      {
        accessor: 'institutionNumber',
        header: intl.messages['profile-account-info-institution-number-label'],
      },
      {
        accessor: 'accountNumber',
        header: intl.messages['profile-account-info-account-number-label'],
      },
    ];
  };

  const accountDetailsColumn = determineAccountDetailsColumn();
  const accountDetailsInfo = determineAccountDetailsInfo();

  return (
    <>
      <TitleContainer>
        <Title
          title={profileEn['profile-account-info-title']}
          titleFor='account-info'
          page={page}
        />
      </TitleContainer>

      <CardInfoGroup>
        <Details
          columns={accountDetailsColumn}
          data={accountDetailsInfo}
          defaultValue='--'
        />
      </CardInfoGroup>
    </>
  );
};

DirectDepositAccountInfo.propTypes = {
  accountInfo: PropTypes.shape({
    bank_details: PropTypes.shape({
      routing_number: PropTypes.string,
      transit_number: PropTypes.string,
      institution_number: PropTypes.string,
      account_number: PropTypes.string,
    }),
  }),
};

DirectDepositAccountInfo.defaultProps = {
  accountInfo: null
};

export default DirectDepositAccountInfo;
