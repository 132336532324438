import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
// Import Icons
import Balance from './icons/balance';
import Expiry from './icons/expiry';
import Status from './icons/status';
import PwrBalance from './icons/pwr-balance';
// Import Component Styles
import { WidgetIcon } from './styles';

const Icon = withTheme(({ theme, type, size }) => {
  const selectIcon = type => {
    switch (type) {
    case 'balance':
      return <Balance size={size} color={theme.brand.tertiary} />;
    case 'expiry':
      return <Expiry size={size} color={theme.brand.tertiary} />;
    case 'status':
      return <Status size={size} color={theme.brand.tertiary} />;
    case 'pwr-balance':
      return <PwrBalance size={size} color={theme.brand.tertiary} />;
    default:
      return null;
    }
  };
  return <WidgetIcon>{selectIcon(type)}</WidgetIcon>;
});

Icon.propTypes = {
  // Determines icon to render
  type: PropTypes.oneOf(['balance', 'expiry', 'status', 'pwr-balance']).isRequired,
  size: PropTypes.number,
};

Icon.defaultProps = {
  size: 75,
};

export default Icon;
