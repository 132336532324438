import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
// Import Shared Components
import { Loader, ErrorComponent } from 'shared-components';
// Import Shared Utils
import { get } from 'shared-components/src/utils/http';
// Import Hooks
import useAlert from '../../hooks/useAlert';
// Import Global Components
import Alert from '../../components/Alert';
// Import Local Components
import Transactions from './components/Transactions';
import AccountDetails from './components/AccountDetails';
import HomeHeader from './components/HomeHeader';

export default () => {
  // Set Component States
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useAlert();
  const [cardClosedAlert, setCardClosedAlert] = useState();

  // Set Data States
  const [cardholder, setCardholder] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentAccountPrimaryCard, setCurrentAccountPrimaryCard] = useState(
    null
  );
  const [primaryCardStatus, setPrimaryCardStatus] = useState(null);

  const [accountBalance, setAccountBalance] = useState(null);
  const [accountId, setAccountId] = useState(null);

  const intl = useIntl();

  // Set Effects
  useEffect(() => {
    const fetchData = async () => {
      setError(false);

      try {
        // seperate calls
        const cardholderData = await get(
          '/api/v1/cardholder_portal/cardholder'
        );

        setCardholder(cardholderData.data);

        // Set the currentAccount as the primary cardholder account during the initial load
        const account = cardholderData.data.accounts.find(
          acc =>
            acc.processor_reference ===
            cardholderData.data.primary_processor_reference
        );
        const primaryCard = account.cards[0];
        setCurrentAccount(account);

        // NOTE: Set AccountPrimaryCard as the first card in the list
        setCurrentAccountPrimaryCard(primaryCard);
        setPrimaryCardStatus(primaryCard.status);
        // If primary card is closed, display an alert
        if (primaryCard.status === 'closed') {
          setCardClosedAlert({
            for: 'card-closed',
            type: 'info',
          });
        }

        // Set accountId here
        setAccountId(account.id);

        // Fetch Account Balance
        const balanceData = await get(
          `/api/v1/cardholder_portal/cardholder/accounts/${account.id}/balance`
        );

        // Set account balance
        setAccountBalance(balanceData.data);
      } catch (e) {
        setError(true);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  if (error) return <ErrorComponent />;
  if (loading) return <Loader />;

  const homeHeaderData = {
    lastFourDigits: currentAccountPrimaryCard
      ? currentAccountPrimaryCard.last_four_digits
      : null,
    user: {
      firstName: cardholder.first_name,
      lastName: cardholder.last_name,
    },
    accountId: currentAccount.id,
    cardStatus: primaryCardStatus,
  };

  return (
    <>
      {alert && (
        <Alert
          alert={intl.messages[`${alert.for}-alert`]}
          alertFor={alert.for}
          type={alert.type}
        />
      )}
      <HomeHeader data={homeHeaderData} setAlert={setAlert} />
      {cardClosedAlert && (
        <Alert
          alert={intl.messages[`${cardClosedAlert.for}-alert`]}
          alertFor={cardClosedAlert.for}
          type={cardClosedAlert.type}
        />
      )}
      <AccountDetails
        accountId={accountId}
        account={currentAccount}
        status={currentAccount.status_code}
        expiryDate={currentAccountPrimaryCard.expiry_date}
        accountBalance={accountBalance}
      />
      <Transactions accountId={accountId} accountBalance={accountBalance} />{' '}
    </>
  );
};
