import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
// Import Shared Utils
import { getAccountStatus } from 'shared-components/src/utils/cardholders';
import { formatExpiryDate } from 'shared-components/src/utils/formatDateTime';
import { formatCurrencyCode } from 'shared-components/src/utils/formatCurrency';
// Import Local Components
import Widget from './components/Widget';
// Import Component Styles
import { WidgetGroup } from '../../styles';

const AccountDetails = ({ accountBalance, expiryDate, status, account: {pwr_balance: pwrBalanceInt} }) => {
  const renderBalance = () => {
    if (!accountBalance) return <span>--</span>;

    return (
      <FormattedNumber
        style="currency" // eslint-disable-line react/style-prop-object
        currency={formatCurrencyCode(accountBalance.currency)}
        value={parseFloat(accountBalance.balance)}
      />
    );
  };

  const renderPwrBalance = () => {
    return (
      <FormattedNumber
        style="currency" // eslint-disable-line react/style-prop-object
        currency={formatCurrencyCode(accountBalance.currency)}
        value={parseFloat(pwrBalanceInt) / 100}
      />
    );
  };
  const pwrBalance = pwrBalanceInt ? renderPwrBalance() : null;

  return (
    <WidgetGroup>
      <Widget widgetLabel="Available Balance" widgetType="balance">
        {renderBalance()}
      </Widget>
      <Widget widgetLabel="Account Status" widgetType="status">
        {getAccountStatus(status)}
      </Widget>
      <Widget widgetLabel="Expiry Date" widgetType="expiry">
        {formatExpiryDate(expiryDate)}
      </Widget>
      {pwrBalance && (
        <Widget widgetLabel="PWR Outstanding Balance" widgetType="pwr-balance">
          {pwrBalance}
        </Widget>
      )}
    </WidgetGroup>
  );
};

AccountDetails.propTypes = {
  accountBalance: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
  }).isRequired,
  status: PropTypes.string.isRequired,
  expiryDate: PropTypes.string.isRequired,
  account: PropTypes.shape({
    pwr_balance: PropTypes.number,
  }),
};

AccountDetails.defaultProps = {
  account: null
};

export default AccountDetails;
