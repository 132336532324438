import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ color, size }) => (

  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 115 115">
    <defs>
      <style jsx="true">{`.pwr-balance-1{opacity:0.2;}.pwr-balance-2{fill:${color};}`}</style>
    </defs>
    <g className="pwr-balance-1">
      <circle className="pwr-balance-2" cx="56.5" cy="56.5" r="55.5" />
    </g>
    <title>images</title>
    <path className="pwr-balance-2" d="M56.5,113A56.5,56.5,0,1,1,113,56.5,56.56,56.56,0,0,1,56.5,113Zm0-111A54.5,54.5,0,1,0,111,56.5,54.56,54.56,0,0,0,56.5,2Z" />
    <g transform="scale(0.55)">
      <path 
        fill="none"
        opacity="1.000000"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.000000" 
        d="M112.500000,141.500000 C111.883469,135.542267 109.792458,133.566803 103.999962,133.503830 C101.833549,133.480270 99.635628,133.261032 97.504898,133.537720 C80.097656,135.798325 64.450577,129.050842 48.533897,123.895348 C45.030048,122.760437 41.704159,121.169998 38.013847,120.430855 C32.704483,119.367432 30.809357,119.918457 28.999996,124.000000 C27.519581,127.339493 28.755964,130.496185 33.020866,132.963943 C40.445881,137.260193 48.005955,141.322662 55.498520,145.502640 C65.337868,150.991867 75.178238,156.479462 84.994324,162.010071 C88.940559,164.233490 92.661179,164.232986 97.045837,162.624985 C108.073959,158.580536 119.414688,155.391968 130.464630,151.402039 C135.746750,149.494736 140.978394,148.628357 146.500000,149.000000"
      />
      <path 
        fill="none"
        opacity="1.000000"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.000000" 
        d="M147.000000,149.000000 C147.166656,149.666672 147.435089,150.325882 147.484543,151.001129 C148.106949,159.499405 148.098877,159.500000 156.500000,159.500000 C160.500000,159.500000 164.501831,159.425003 168.499451,159.522491 C172.028885,159.608536 173.597977,158.309982 173.564636,154.499435 C173.415863,137.500732 173.426971,120.499443 173.556793,103.500435 C173.585449,99.747864 172.258057,98.301918 168.497971,98.447647 C163.172638,98.654060 157.832077,98.570114 152.500443,98.475243 C148.971298,98.412445 147.386795,99.688759 147.434875,103.500824 C147.602997,116.832039 147.520493,130.166748 147.474045,143.499908 C147.468246,145.164307 147.970978,146.913773 147.000000,148.500000"
      />
      <path 
        fill="none"
        opacity="1.000000"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.000000" 
        d="M146.500000,109.000000 C131.520721,107.770256 117.390892,110.713783 103.978691,117.457626 C101.383636,118.762451 98.427826,119.014793 95.499985,119.004578 C90.666710,118.987717 85.833298,118.987442 81.000008,119.003128 C74.705551,119.023552 72.802856,120.841179 72.484787,126.999214 C72.424240,128.171371 72.166664,129.333328 72.000000,130.500000"
      />
      <path 
        fill="none"
        opacity="1.000000"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.000000" 
        d="M82.000000,61.500000 C81.185699,58.409401 78.228668,56.931870 76.070320,57.511749 C73.432442,58.220455 69.852463,58.730701 69.123520,62.978294 C68.491676,66.660080 71.138695,67.640846 73.055923,69.180351 C74.435379,70.288033 76.309921,70.808655 78.005707,71.485710 C80.317444,72.408661 82.065712,73.619698 82.032272,76.500374 C81.997887,79.461594 80.356010,81.586082 77.959869,82.378670 C74.347908,83.573448 70.488312,83.853043 67.500000,80.500000"
      />
      <path 
        fill="none"
        opacity="1.000000"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.000000" 
        d="M72.000000,38.000000 C59.237190,39.877438 50.016605,46.600174 45.044624,58.518616 C36.574833,78.821701 50.792690,100.013985 70.498940,103.006973 C86.461243,105.431313 103.325562,95.811760 107.265839,77.948349 C110.843773,61.727650 103.649376,46.650970 85.956833,39.608452 C81.795586,37.952068 77.084267,37.741585 72.500000,38.000000"
      />
    </g>
  </svg>
);

Icon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export default Icon;